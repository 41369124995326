
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexHGtWImw1RvMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/index.vue?macro=true";
import { default as _91id_93GunBGQ6eYHMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/requests/[id].vue?macro=true";
import { default as addS0Biy1l09jMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/requests/add.vue?macro=true";
import { default as indexgQ3bUSvjgwMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/requests/index.vue?macro=true";
import { default as index9HuaGfmBgcMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/index.vue?macro=true";
import { default as changeyP4i6c82Q4Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/[id]/change.vue?macro=true";
import { default as indexcuuDw05PcgMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/[id]/index.vue?macro=true";
import { default as addB61GmcaXKmMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/add.vue?macro=true";
import { default as indexOfQKshrYFOMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/index.vue?macro=true";
import { default as indexgCMXjwl7dXMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/_billing/[id]/index.vue?macro=true";
import { default as indexgMcABqMCfAMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/_billing/index.vue?macro=true";
import { default as blocked_45phone_45numbersfyZheYDxzaMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/blocked-phone-numbers.vue?macro=true";
import { default as indexkqr5jcZ1Q6Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/company/index.vue?macro=true";
import { default as indexsbODtvKi1jMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/custom-fields/index.vue?macro=true";
import { default as indext9lHirVVW1Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/index.vue?macro=true";
import { default as statusFNgXbk3tPZMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/status.vue?macro=true";
import { default as indexx9OWEPUiEwMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/tags/index.vue?macro=true";
import { default as indextvGxxVowVQMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/templates/index.vue?macro=true";
import { default as indexpcZiZEj7aJMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/trust-hub/index.vue?macro=true";
import { default as change7DbnS0BABJMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/[id]/change.vue?macro=true";
import { default as indexqADbYU6cEUMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/[id]/index.vue?macro=true";
import { default as set_45passwordgy7aSkdadbMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/[id]/set-password.vue?macro=true";
import { default as addObHvMWoTzmMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/add.vue?macro=true";
import { default as indexvlsYhBVs42Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/index.vue?macro=true";
import { default as indexfdZslwNSqNMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/work-types/index.vue?macro=true";
import { default as configurationM6kITcuotmMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration.vue?macro=true";
import { default as boardc6aLOwdcUMMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/board.vue?macro=true";
import { default as calendarZ1tPPVH26nMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/calendar.vue?macro=true";
import { default as _91id_93S9pYFKswWKMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/calls/[id].vue?macro=true";
import { default as index404CAJMtSXMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/calls/index.vue?macro=true";
import { default as changeoqTPjcX56oMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/[id]/change.vue?macro=true";
import { default as indexGFGZ4z1frlMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/[id]/index.vue?macro=true";
import { default as addyEgfeWtYRaMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/add.vue?macro=true";
import { default as index9NB7FxKEqvMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/index.vue?macro=true";
import { default as indexCGM4CRXf2WMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/conversations/index.vue?macro=true";
import { default as indexSCIRPg13CuMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/index.vue?macro=true";
import { default as changeBDLm2ll4dcMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/[id]/change.vue?macro=true";
import { default as indexTBPn9lqvPnMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/[id]/index.vue?macro=true";
import { default as merge9jqjyHp6xMMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/[id]/merge.vue?macro=true";
import { default as addZDU8S6CbB2Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/add.vue?macro=true";
import { default as indexGMxlGKDDGCMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/index.vue?macro=true";
import { default as changeu95k1yRKAvMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/[id]/change.vue?macro=true";
import { default as indexTyYtrazRECMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/[id]/index.vue?macro=true";
import { default as index1FGmCtuv0jMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/index.vue?macro=true";
import { default as search_45availablec0Ft1y9bZZMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/search-available.vue?macro=true";
import { default as credentialskT8534crg6Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/angi/credentials.vue?macro=true";
import { default as changeHAITiUINZbMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/[id]/change.vue?macro=true";
import { default as indexk7Eqnzrf2OMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/[id]/index.vue?macro=true";
import { default as addAK9M51ydDKMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/add.vue?macro=true";
import { default as indexqJdAYJRfI1Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/index.vue?macro=true";
import { default as _91id_93mDtkTvE6H3Meta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/networx/credentials/[id].vue?macro=true";
import { default as indexSMIWS3pFeeMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/networx/credentials/index.vue?macro=true";
import { default as _91id_93HVjTPX3K0BMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/opportunities/[id].vue?macro=true";
import { default as indexKEd2ZI38nAMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/opportunities/index.vue?macro=true";
import { default as proxiesEqiEoYRguGMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/proxies.vue?macro=true";
import { default as crmycFyPy209ZMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm.vue?macro=true";
import { default as indexiwj2Qq9DXUMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/index.vue?macro=true";
import { default as _91companyId_93VboN2UsO2OMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/[companyId].vue?macro=true";
import { default as changegmqoTPnzHzMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/change.vue?macro=true";
import { default as indexB1QALxqjGqMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/index.vue?macro=true";
import { default as loginuGY4pWRT2nMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/login.vue?macro=true";
import { default as _91token_93fRu0T5q1qUMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completedFNRGDEqrdMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/complete.vue?macro=true";
import { default as doneFrYkr0eHMpMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/done.vue?macro=true";
import { default as indexjAqxzaVTPQMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetsyTZCg4FdmMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset.vue?macro=true";
import { default as passwordVjAyotvv3oMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/account/password.vue?macro=true";
import { default as privacy_45policyJqU6TWVTeZMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45useGOVBnIuJ5qMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/docs/terms-of-use.vue?macro=true";
import { default as indexcMGfUhgWhMMeta } from "/home/runner/work/callcenter/callcenter/frontend/pages/index.vue?macro=true";
export default [
  {
    name: _91companyId_93VboN2UsO2OMeta?.name,
    path: "/:companyId()",
    meta: _91companyId_93VboN2UsO2OMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId].vue"),
    children: [
  {
    name: "companyId-ai-claude",
    path: "ai/claude",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/index.vue")
  },
  {
    name: "companyId-ai-claude-requests-id",
    path: "ai/claude/requests/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/requests/[id].vue")
  },
  {
    name: "companyId-ai-claude-requests-add",
    path: "ai/claude/requests/add",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/requests/add.vue")
  },
  {
    name: "companyId-ai-claude-requests",
    path: "ai/claude/requests",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/claude/requests/index.vue")
  },
  {
    name: "companyId-ai",
    path: "ai",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/index.vue")
  },
  {
    name: "companyId-ai-prompts-id-change",
    path: "ai/prompts/:id()/change",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/[id]/change.vue")
  },
  {
    name: "companyId-ai-prompts-id",
    path: "ai/prompts/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/[id]/index.vue")
  },
  {
    name: "companyId-ai-prompts-add",
    path: "ai/prompts/add",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/add.vue")
  },
  {
    name: "companyId-ai-prompts",
    path: "ai/prompts",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/ai/prompts/index.vue")
  },
  {
    name: configurationM6kITcuotmMeta?.name,
    path: "configuration",
    meta: configurationM6kITcuotmMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration.vue"),
    children: [
  {
    name: "companyId-configuration-_billing-id",
    path: "_billing/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/_billing/[id]/index.vue")
  },
  {
    name: "companyId-configuration-_billing",
    path: "_billing",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/_billing/index.vue")
  },
  {
    name: "companyId-configuration-blocked-phone-numbers",
    path: "blocked-phone-numbers",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/blocked-phone-numbers.vue")
  },
  {
    name: "companyId-configuration-company",
    path: "company",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/company/index.vue")
  },
  {
    name: "companyId-configuration-custom-fields",
    path: "custom-fields",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/custom-fields/index.vue")
  },
  {
    name: "companyId-configuration",
    path: "",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/index.vue")
  },
  {
    name: "companyId-configuration-status",
    path: "status",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/status.vue")
  },
  {
    name: "companyId-configuration-tags",
    path: "tags",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/tags/index.vue")
  },
  {
    name: "companyId-configuration-templates",
    path: "templates",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/templates/index.vue")
  },
  {
    name: "companyId-configuration-trust-hub",
    path: "trust-hub",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/trust-hub/index.vue")
  },
  {
    name: "companyId-configuration-users-id-change",
    path: "users/:id()/change",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/[id]/change.vue")
  },
  {
    name: "companyId-configuration-users-id",
    path: "users/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/[id]/index.vue")
  },
  {
    name: "companyId-configuration-users-id-set-password",
    path: "users/:id()/set-password",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/[id]/set-password.vue")
  },
  {
    name: "companyId-configuration-users-add",
    path: "users/add",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/add.vue")
  },
  {
    name: "companyId-configuration-users",
    path: "users",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/users/index.vue")
  },
  {
    name: "companyId-configuration-work-types",
    path: "work-types",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/configuration/work-types/index.vue")
  }
]
  },
  {
    name: crmycFyPy209ZMeta?.name,
    path: "crm",
    meta: crmycFyPy209ZMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm.vue"),
    children: [
  {
    name: "companyId-crm-board",
    path: "board",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/board.vue")
  },
  {
    name: "companyId-crm-calendar",
    path: "calendar",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/calendar.vue")
  },
  {
    name: "companyId-crm-calls-id",
    path: "calls/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/calls/[id].vue")
  },
  {
    name: "companyId-crm-calls",
    path: "calls",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/calls/index.vue")
  },
  {
    name: "companyId-crm-campaigns-id-change",
    path: "campaigns/:id()/change",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/[id]/change.vue")
  },
  {
    name: "companyId-crm-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/[id]/index.vue")
  },
  {
    name: "companyId-crm-campaigns-add",
    path: "campaigns/add",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/add.vue")
  },
  {
    name: "companyId-crm-campaigns",
    path: "campaigns",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/campaigns/index.vue")
  },
  {
    name: "companyId-crm-conversations",
    path: "conversations",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/conversations/index.vue")
  },
  {
    name: "companyId-crm",
    path: "",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/index.vue")
  },
  {
    name: "companyId-crm-leads-id-change",
    path: "leads/:id()/change",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/[id]/change.vue")
  },
  {
    name: "companyId-crm-leads-id",
    path: "leads/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/[id]/index.vue")
  },
  {
    name: "companyId-crm-leads-id-merge",
    path: "leads/:id()/merge",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/[id]/merge.vue")
  },
  {
    name: "companyId-crm-leads-add",
    path: "leads/add",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/add.vue")
  },
  {
    name: "companyId-crm-leads",
    path: "leads",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/leads/index.vue")
  },
  {
    name: "companyId-crm-phone-numbers-id-change",
    path: "phone-numbers/:id()/change",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/[id]/change.vue")
  },
  {
    name: "companyId-crm-phone-numbers-id",
    path: "phone-numbers/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/[id]/index.vue")
  },
  {
    name: "companyId-crm-phone-numbers",
    path: "phone-numbers",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/index.vue")
  },
  {
    name: "companyId-crm-phone-numbers-search-available",
    path: "phone-numbers/search-available",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/phone-numbers/search-available.vue")
  },
  {
    name: "companyId-crm-scrapper-angi-credentials",
    path: "scrapper/angi/credentials",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/angi/credentials.vue")
  },
  {
    name: "companyId-crm-scrapper-customers-id-change",
    path: "scrapper/customers/:id()/change",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/[id]/change.vue")
  },
  {
    name: "companyId-crm-scrapper-customers-id",
    path: "scrapper/customers/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/[id]/index.vue")
  },
  {
    name: "companyId-crm-scrapper-customers-add",
    path: "scrapper/customers/add",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/add.vue")
  },
  {
    name: "companyId-crm-scrapper-customers",
    path: "scrapper/customers",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/customers/index.vue")
  },
  {
    name: "companyId-crm-scrapper-networx-credentials-id",
    path: "scrapper/networx/credentials/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/networx/credentials/[id].vue")
  },
  {
    name: "companyId-crm-scrapper-networx-credentials",
    path: "scrapper/networx/credentials",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/networx/credentials/index.vue")
  },
  {
    name: "companyId-crm-scrapper-opportunities-id",
    path: "scrapper/opportunities/:id()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/opportunities/[id].vue")
  },
  {
    name: "companyId-crm-scrapper-opportunities",
    path: "scrapper/opportunities",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/opportunities/index.vue")
  },
  {
    name: "companyId-crm-scrapper-proxies",
    path: "scrapper/proxies",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/crm/scrapper/proxies.vue")
  }
]
  },
  {
    name: "companyId",
    path: "",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/[companyId]/index.vue")
  }
]
  },
  {
    name: "account-change",
    path: "/account/change",
    meta: changegmqoTPnzHzMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexB1QALxqjGqMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginuGY4pWRT2nMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/login.vue")
  },
  {
    name: password_45resetsyTZCg4FdmMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetsyTZCg4FdmMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordVjAyotvv3oMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/account/password.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/docs/terms-of-use.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexcMGfUhgWhMMeta || {},
    component: () => import("/home/runner/work/callcenter/callcenter/frontend/pages/index.vue")
  }
]